import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect } from 'react';

import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { getDefaultStaticProps } from '@/utility/getDefaultProps';
import { t } from '@/utility/localization';

import LoadingMainContent from '@/features/community/components/LoadingMainContent';

import ErrorDisplay from '@/components/common/ErrorDisplay';
import NPLButton from '@/components/npl/NPLButton';

const MISSING_PAGE_IMAGE_URL = `${STATIC_ASSET_BASE_URL}/nasIO/common/png/nasio-404-error-image.png`;

const NotFound = ({ redirectLink, hasCommunityLink }) => {
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    if (redirectLink) {
      // Using window.location.href here as router.push does not store the correct referrer
      window.location.href = redirectLink;
    }
  }, [redirectLink]);

  const routeToHomepage = () => {
    router.push('/');
  };

  const routeToCommunity = () => {
    const communitySlug = pathname?.split('/')[1];
    router.push(`/${communitySlug}`);
  };

  const renderButton = () => {
    if (hasCommunityLink) {
      return (
        <>
          <NPLButton
            hierarchy="outline"
            size="lg"
            buttonText={t('back-to-home')}
            onClick={routeToHomepage}
          />
          <NPLButton
            hierarchy="accent_primary"
            size="lg"
            buttonText={t('back-to-community')}
            onClick={routeToCommunity}
          />
        </>
      );
    }
    return (
      <>
        <NPLButton
          hierarchy="accent_primary"
          size="lg"
          buttonText={t('back-to-home')}
          onClick={routeToHomepage}
        />
      </>
    );
  };

  return (
    <>
      {redirectLink ? (
        <LoadingMainContent />
      ) : (
        <ErrorDisplay
          title={t('the-page-doesnt-exist')}
          description={() => (
            <>
              {t(
                'you-may-have-mistyped-the-address-or-the-page-may-have-moved'
              )}
            </>
          )}
          imageURL={MISSING_PAGE_IMAGE_URL}
          renderButton={renderButton()}
          errorCode="404"
        />
      )}
    </>
  );
};

export default NotFound;

export async function getStaticProps(ctx) {
  return getDefaultStaticProps(ctx);
}
