
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[communityLink]/challenges/[challengeSlug]",
      function () {
        return require("private-next-pages/[communityLink]/challenges/[challengeSlug]/index.page.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[communityLink]/challenges/[challengeSlug]"])
      });
    }
  