import React from 'react';

import config from '@/utility/config';
import { getDefaultStaticProps } from '@/utility/getDefaultProps';
import { DB_CHALLENGE_PUBLIC_PAGE_CONTENT_TYPE_ID } from '@/utility/pageTypes';

import ChallengeLandingPage from '@/features/Challenges/ChallengeLandingPage';

import { getCommunityDataService } from '@/services/communitiesService';
import { getChallengeBySlug } from '@/services/community/CommunityChallengesService';

import AuthNavbarPageLayout from '@/components/features/Navbar/AuthNavbarPageLayout';

import { notFoundPageWithRevalidation } from '../../constants';

export async function getStaticPaths() {
  return { paths: [], fallback: 'blocking' };
}

export async function getStaticProps(ctx) {
  const defaultStaticProps = await getDefaultStaticProps(ctx);
  const { communityLink, challengeSlug } = ctx.params;

  const communitySlug = `/${communityLink}`;

  const res = await getCommunityDataService(communitySlug);

  const { error } = res;

  // if community doesn't even exist, don't need to check for challenge
  if (error) {
    return notFoundPageWithRevalidation;
  }

  // Fetch data for the specific challenge slug
  const resp = await getChallengeBySlug({
    communityLink: communityLink,
    slug: challengeSlug
  });
  const challengeData = resp?.data?.data;
  const challengeError = resp?.error;

  //If the community is inactive, the resource will be not available, return 404
  if (
    challengeData &&
    challengeData.communityInfo &&
    !challengeData.communityInfo.isActive
  ) {
    return notFoundPageWithRevalidation;
  }

  // if ChallengeData exists, return challenge page
  if (challengeData && !challengeError) {
    const pageInfo = {
      templateData: {
        challengeInfo: challengeData,
        communityInfo: res?.data,
        communityLink,
        challengeSlug: challengeSlug
      },
      templateContentType: DB_CHALLENGE_PUBLIC_PAGE_CONTENT_TYPE_ID
    };

    return {
      props: { pageInfo, ...defaultStaticProps?.props },
      revalidate: config.revalidateSec
    };
  }

  // else just return notFound
  return notFoundPageWithRevalidation;
}

const ChallengePage = (props) => {
  return <ChallengeLandingPage {...props} />;
};

ChallengePage.getLayout = function getLayout(page) {
  const pageInfo = page?.props?.pageInfo?.templateData;

  return (
    <AuthNavbarPageLayout communityInfo={pageInfo?.communityInfo}>
      {(userCommunitiesData) =>
        React.cloneElement(page, { userCommunitiesData })
      }
    </AuthNavbarPageLayout>
  );
};

export default ChallengePage;
